import React from 'react';

export default class About extends React.Component {
    render() {
        return (
            <div className="section" id="projects">
                

            </div>
        );
    }
}